@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.bai-jamjuree-extralight {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.bai-jamjuree-light {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.bai-jamjuree-regular {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bai-jamjuree-medium {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.bai-jamjuree-semibold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.bai-jamjuree-bold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bai-jamjuree-extralight-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.bai-jamjuree-light-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.bai-jamjuree-regular-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.bai-jamjuree-medium-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.bai-jamjuree-semibold-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.bai-jamjuree-bold-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  font-style: italic;
}


:root {
  font-family: 'Bai Jamjuree', sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  background-color: var(--color--dark-black);
  color: var(--base-color-neutral--white);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;

}


a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  outline: 0;
}



h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}




.step_img-item {
  aspect-ratio: 550/397;
}