.connect_modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 200ms ease-out;
}

.connect_modal-container:not(.open) {
  opacity: 0;
  pointer-events: none;
}

.connect_modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}

.connect_modal-stroke {
  content: " ";
  position: absolute;
  background-image: linear-gradient(#00f981, #0056fb);
  padding: 1px;
  border-radius: 1rem;
  width: calc(100% - 2rem);
  max-width: 24rem;
}

.connect_modal-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #1b1b1b;
  isolation: isolate;
}

.connect_modal-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
  border-radius: 999rem;
  padding: 0;
  top: 0.5rem;
  right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color 200ms ease-out;
}

.connect_modal-close:hover {
  background-color: rgba(255,255,255,0.15);
}

.connect_modal-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.connect_modal-option {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  background-color: #323232;
  border: 2px solid #5b5b5b !important;
  outline: none !important;
  font-size: 1.25rem;
  transition: background-color 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;
}

.connect_modal-option:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.connect_option-img {
  width: 3rem;
  height: 3rem;
}